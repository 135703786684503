.card {
  position: relative;
  padding: $card-padding;
  margin: $card-margin;
  border-radius: $border-radius;
  background-color: $card-background;
  box-shadow: $card-box-shadow;
}

.close-btn {
  position: absolute;
  right: -0.5em;
  top: -0.5em;
}
