.form {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  padding: 0 $card-padding $card-padding;
}

.form-block {
  flex: 1 1 auto;
  max-width: 800px;

  @include media('<desktop') {
    width: 100%;
  }

  &-main {
    flex: 3 2 auto;
    margin: 0 $card-padding * 2 0 0;
  }
}
