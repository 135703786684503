.auth {
  &-form {
    width: 80%;
    max-width: 350px;
    margin: auto;
    padding: 1.5em 2em;
    text-align: center;

    button[type='submit'] {
      margin-top: 1.5em;
    }

    .nav-link {
      display: block;
      margin-top: 1em;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-title {
    text-align: center;
  }
}
