//fix duplicate and wrong position on chunk error
.cdk-global-overlay-wrapper + .cdk-global-overlay-wrapper {
  .i-snackbar {
    display: none;
  }
}

.cdk-overlay-pane {
  bottom: 0;
  right: 0;

  .mat-snack-bar-container {
    min-height: 0;
    padding: 0;
  }
  // end fix

  .i-snackbar {
    display: block;
    border-radius: 5px;
    color: #000000;
    background: #ffffff;

    .snackbar-block {
      position: relative;
      padding: 14px 16px;

      .snackbar-title {
      }

      .snackbar-message {
        margin: 0;
      }

      .snackbar-close-btn {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px 10px 15px 15px;
        outline: none;
        border: none;
        transition: opacity 0.3s ease-in-out;
        background: transparent;
        color: #ffffff;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    // TYPES ----------------------------
    &.i-snackbar-success {
      background: mat-color($theme-primary);
      color: mat-contrast($theme-primary, 500);
      box-shadow: 0 5px 6px transparentize(mat-color($theme-primary), 0.42);

      .snackbar-title {
        color: mat-contrast($theme-primary, 500);
      }
    }

    &.i-snackbar-error {
      background: mat-color($theme-warn);
      color: mat-contrast($theme-warn, 500);
      box-shadow: 0 5px 6px transparentize(mat-color($theme-warn), 0.42);

      .snackbar-title {
        color: mat-contrast($theme-warn, 500);
      }
    }

    &.i-snackbar-warning {
      background: $warning-color;
      color: #ffffff;
      box-shadow: 0 5px 6px transparentize($warning-color, 0.42);

      .snackbar-title {
        color: #ffffff;
      }
    }
  }
}
