/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/theme';
@import 'assets/styles/modules/variables';
@import 'assets/styles/base/common';
@import 'assets/styles/base/typography';
@import 'assets/styles/base/mixins';
@import 'assets/styles/base/hacks';
@import 'assets/styles/base/include-media';
@import 'assets/styles/components/copmonents';
@import 'assets/styles/components/forms';
@import 'assets/styles/components/snackbar';
@import 'assets/styles/components/tables';
@import 'assets/styles/components/details';
@import 'assets/styles/components/tabs';
@import 'assets/styles/components/chips';
@import 'assets/styles/modules/auth';
@import 'assets/styles/modules/material';

html,
body {
  height: 100%;
  background: $base-background-color;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a {
  color: $base-link-color;
  &:hover {
    font-weight: bold;
  }
}
