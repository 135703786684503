.details {
  display: flex;

  @include media('<desktop') {
    flex-wrap: wrap;
  }

  .details-image {
    flex: 1 1 auto;
    align-self: start;
    max-width: 200px;
    margin: 0 1em 1em 0;
  }

  .details-description {
    flex: 1 1 auto;
  }

  .details-separator {
    justify-content: center;
    color: $label-color;
    margin-bottom: 5px;
  }
}

// Always wrap in div.w100!
//-----
.details-description {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 800px;
  height: fit-content;
  overflow: hidden;

  ul {
    @extend %listreset;
    max-width: 450px;
    align-self: flex-start;

    li {
      display: flex;
      line-height: 1.3;

      &:not(:last-child) {
        margin: 0.4em 0;
      }

      .label {
        min-width: 130px;
        margin-right: 1em;
      }
    }

    .chip-list {
      margin: 0.5em 0;

      .label {
        margin-top: 0.3em;
      }
    }
  }
}
