@import 'src/global';

.mat-tab-group {
  margin-top: -$card-padding * 0.5;

  // Normalize Table
  app-filters {
    .card {
      margin: 0;
      padding: $card-padding 0;
      box-shadow: unset;
    }

    & + .card {
      margin: 0;
      padding: 0;
      box-shadow: unset;
    }
  }
}

.tab-content {
  padding-top: 1em;
}
