// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #2d3644;
$gray-menu: #4a4a4a;
$gray-alt: #6e6e6e;
$gray-light-alt: #838383;
$gray-light: #f6f7f7;
$gray-border: #efefef;
$black: #000;
$white: #fff;
$white-gray: #fcfcfc;
$green: #3bb46e;
$green-drop: #f0fff3;
$light-green: #5eca8d;
$lighter-green: #8ee0b2;
$dark-green: #20a65a;
$dark-red: #b43b3b;
$blue-dark: #5856d6;
$red: #d0021b;
$orange: #ff932f;

//
// Base
// ---------------------------------------

$primary-color: #795548;
$primary-color-hover: darken($primary-color, 10);
$back-color: #f5f7f7;
$secondary-color: $blue-dark;
$heading-color: $gray;
$label-color: #777777;
$base-text-color: $gray;
$base-label-color: #9b9b9b;
$warning-color: $orange;
$error: $dark-red;
$hint-color: $gray;

$base-font-sans-serif: 'Montserrat', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Merriweather', 'Times', 'Baskerville', 'Georgia', serif;

// Body
$base-background-color: $gray-light;
$base-padding: 20px;
$font-size-base: 13px;
$line-height-base: 1.462;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

//
// Elements
// ---------------------------------------
$wrapper-padding: 30px;
$card-background: $white;
$card-margin: 10px;
$card-padding: 20px;
$card-box-shadow: 0 0 15px rgba(32, 36, 39, 0.05);
$border-radius: 6px;
$form-group-margin: 1.6em;

// Liks
$base-link-color: $primary-color;

// Buttons
$btn-color: $white;
$btn-background: $primary-color;
$btn-padding: 10px 15px;
$btn-font-size: 13px;

// Forms
$form-element-padding: 0.5em 1em;
$form-element-border-color: rgba($black, 0.2);
$form-element-focus-border-color: rgba($black, 0.4);
$form-element-font-size: 1rem;
$placeholder-color: $gray;
$border-radius: 6px;

// Headers
$h1-font-size: 40px;
$h2-font-size: 30px !default;
$h3-font-size: 16px;
$h4-font-size: 15px;
$h5-font-size: 13px;
$h6-font-size: 12px;

$headings-font-family: $base-font-sans-serif;
$headings-color: $gray;

$animation-speed: 0.3s;
$trsd: $animation-speed;

$spacer: 30px;
