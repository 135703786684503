@import 'src/global';

.table-wrapper {
  position: relative;
  overflow: auto;

  table {
    width: 100%;
  }

  .mat-row {
    outline: none;
    position: relative;

    .row-link {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    &[ng-reflect-router-link] {
      cursor: pointer;
    }

    &:hover {
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        $back-color 25%,
        $back-color 75%,
        rgba(2, 0, 36, 0) 100%
      );
    }
  }

  .mat-header-cell:not(:first-child, :last-child) {
    padding: 5px;

    &.txt-center {
      text-align: center;
    }
  }

  .mat-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;

    &:not(:first-child, :last-child) {
      padding: 5px;
    }
  }
}

.table-paginator-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  app-universal-button {
    margin: 0.5em 1em;
  }
}
